<template>
    <div id="authentication-card" class="flex flex-col sm:justify-center items-center pt-6 sm:pt-0">
        <div class="logo-wrapper">
            <slot name="logo"/>
        </div>

        <div
            class="auth-content w-full mt-6 shadow-md overflow-hidden sm:rounded-lg">
            <slot/>
        </div>
    </div>
</template>

<style lang="scss" scoped>
#authentication-card {
    width: 665px;
    background: var(--secondary);
    height: max-content;
    margin-top: auto;
    margin-inline: auto;
    margin-bottom: auto;
    border-radius: var(--border-radius);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding: 4rem;

    .logo-wrapper {
        width: 100%;
        margin-block: 0 var(--padding-lg);
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
    }

    .auth-content {
        width: 100%;
    }
}

#authentication-card-after {
    color: #ffffff;
    margin-bottom: auto;
    opacity: 0.5;
    font-size: 14px;
    line-height: 19px;
}
</style>
